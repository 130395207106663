<template>
  <div class="dynamic-tables">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />

    <Widget>
      <DataTable
        class="p-datatable-sm"
        :value="batteries"
        :paginator="true"
        :filters="filters"
        :loading="loading"
        :scrollable="true"
        scrollHeight="650px"
        :rows="1000"
        dataKey="id"
        :rowHover="true"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[25, 50, 100, 1000]"
        currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Einträgen"
      >
        <template #header>
          <div class="table-header">
            <div class="table-header-left">
              <h1 class="page-title">{{ $t('batteries') }}</h1>
            </div>
            <div class="table-header-right">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText type="text" v-model="filters['global']" :placeholder="$t('search')" />
              </span>
              <div class="button-right">
                <router-link class="btn btn-success mb-2 right" :to="{ name: 'BatteryCreatePage' }">
                  <i class="fa fa-plus-circle mr-2"></i>
                  {{ $t('batteryCreate') }}
                </router-link>
              </div>
            </div>
          </div>
        </template>
        <template #empty>
          {{ $t('no_data_found') }}
        </template>
        <template #loading>
          {{ $t('loading') }}
        </template>

        <Column field="number" header="#" sortable filterMatchMode="contains">
          <template #body="slotProps">
            <router-link
              :to="{
                name: 'BatteryEditPage',
                params: { batteryNumber: slotProps.data.number },
              }"
              >{{ slotProps.data.number.toString().padStart(4, '0') }}</router-link
            >
          </template>
          <template #filter>
            <InputText type="text" v-model="filters['number']" class="p-column-filter" />
          </template>
        </Column>

        <Column
          field="manufacturer"
          :header="$t('manufacturer')"
          sortable
          filterMatchMode="contains"
        >
          <template #filter>
            <InputText type="text" v-model="filters['manufacturer']" class="p-column-filter" />
          </template>
        </Column>

        <Column field="type" :header="$t('type')" sortable filterMatchMode="contains">
          <template #filter>
            <InputText type="text" v-model="filters['type']" class="p-column-filter" />
          </template>
        </Column>

        <Column
          field="connectionType"
          :header="$t('connectionType')"
          sortable
          filterMatchMode="startsWith"
        >
          <template #body="slotProps">
            {{ $t(slotProps.data.connectionType) }}
          </template>
          <template #filter>
            <Dropdown
              v-model="filters['connectionType']"
              :options="getBatteryConnectionTypes"
              :placeholder="$t('apply_filter')"
              class="p-column-filter"
              :showClear="true"
            >
              <template #option="slotProps">
                {{ $t(slotProps.option) }}
              </template>
            </Dropdown>
          </template>
        </Column>

        <Column
          field="capacityFormatted"
          :header="$t('capacity_kwh')"
          sortable
          filterMatchMode="contains"
        >
          <template #filter>
            <InputText type="text" v-model="filters['capacityFormatted']" class="p-column-filter" />
          </template>
        </Column>
        <Column
          field="dischargePowerFormatted"
          :header="$t('dischargePower')"
          sortable
          filterMatchMode="contains"
        >
          <template #filter>
            <InputText
              type="text"
              v-model="filters['dischargePowerFormatted']"
              class="p-column-filter"
            />
          </template>
        </Column>
        <Column
          field="batteryModuleCount"
          :header="$t('batteryModuleCount')"
          sortable
          filterMatchMode="contains"
        >
          <template #filter>
            <InputText
              type="text"
              v-model="filters['batteryModuleCount']"
              class="p-column-filter"
            />
          </template>
        </Column>
        <Column
          field="batteryModuleCapacityFormatted"
          :header="$t('batteryModuleCapacity')"
          sortable
          filterMatchMode="contains"
        >
          <template #filter>
            <InputText
              type="text"
              v-model="filters['batteryModuleCapacityFormatted']"
              class="p-column-filter"
            />
          </template>
        </Column>
        <Column
          field="usableCapacityFormatted"
          :header="$t('usableCapacity')"
          sortable
          filterMatchMode="contains"
        >
          <template #filter>
            <InputText
              type="text"
              v-model="filters['usableCapacityFormatted']"
              class="p-column-filter"
            />
          </template>
        </Column>
        <Column
          field="ratedVoltageFormatted"
          :header="$t('ratedVoltage')"
          sortable
          filterMatchMode="contains"
        >
          <template #filter>
            <InputText
              type="text"
              v-model="filters['ratedVoltageFormatted']"
              class="p-column-filter"
            />
          </template>
        </Column>
      </DataTable>
    </Widget>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Widget from '@/components/Widget/Widget';
import Breadcrumbs from '@/components/Breadcrumbs';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';

export default {
  name: 'BatteriesPage',
  components: {
    Breadcrumbs,
    DataTable,
    Widget,
    InputText,
    Column,
    Dropdown,
  },
  data() {
    return {
      loading: true,
      filters: {},
    };
  },
  computed: {
    ...mapGetters(['isLoading', 'getBatteries', 'getBatteryConnectionTypes']),
    breadcrumbs() {
      return [{ name: 'Home', route: { name: 'home' } }, { name: this.$t('batteries') }];
    },
    batteries() {
      const batteries = [];
      this.getBatteries.map((battery) => {
        battery.capacityFormatted = this.$n(battery.capacity, 'decimal_triple');
        battery.dischargePowerFormatted = this.$n(battery.dischargePower, 'decimal_triple');
        battery.usableCapacityFormatted = this.$n(battery.usableCapacity, 'decimal_triple');
        battery.ratedVoltageFormatted = this.$n(battery.ratedVoltage, 'decimal_triple');
        battery.batteryModuleCapacityFormatted = this.$n(
          battery.batteryModuleCapacity,
          'decimal_triple'
        );

        batteries.push(battery);
      });

      return batteries;
    },
  },
  methods: {
    ...mapActions(['fetchBatteries', 'fetchBatteryConnectionTypes']),
  },
  async created() {
    await this.fetchBatteries();
    await this.fetchBatteryConnectionTypes();
    this.loading = false;
  },
};
</script>

<style scoped lang="scss">
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(1),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(1) {
  width: 60px;
  text-align: center;
}
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(n + 4),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(n + 4) {
  width: 9%;
  text-align: center;
}
.table-header {
  display: flex;
  justify-content: space-between;
}
.table-header-left {
  display: flex;
  justify-content: flex-start;
}
.table-header-right {
  display: flex;
  justify-content: flex-end;
  column-gap: 1em;
}
/** Fix Dropdown overlay */
::v-deep .p-datatable-scrollable-header {
  overflow: visible;
}
</style>
